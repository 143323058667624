import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'


const SecondPage = ({ location }) => {
 return (
   <Layout>
     <SEO title="St-Martin - Le Tshirt" />
     <section className="section success-content">
      <h1>Une erreur est survenue !</h1>
       <img src="../Jacques_StM.png" alt="St Jacques" className="logo-success"/>

      <Link to="/" className="button is-primary">Shop again</Link>
     </section>
   </Layout>
 )
}

export default SecondPage
